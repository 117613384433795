import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import RecordingDisplay from './recording';
import ListRecordings from './list';
import SongsBySession from './sessions'

class MainPage extends React.Component {

  render() {
    
    return (
      <>
       <span><a href="covers/index.php">Beach Boys Covers Database</a></span>
      </>
    )
  }

}

ReactDOM.render(

  <BrowserRouter basename = "/beachboys/">
    <div>
      <Route exact path="/">
      <MainPage/>
      </Route>
      <Switch>
        <Route path="/recording/:recordingID">
          <RecordingDisplay/>
        </Route>
        <Route path="/list/sessions">
          <SongsBySession/>
        </Route>
        <Route path="/list/:pageNumber">
          <ListRecordings/>
        </Route>
        <Route path="/list/">
          <ListRecordings/>
        </Route>
      </Switch>
    </div>
  </BrowserRouter>,
  document.getElementById('root')
);



