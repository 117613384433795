import React from 'react';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { withRouter, Link } from "react-router-dom";
import * as constants from './constants.js';
import { getArtistName, formatDateRangeSession } from './utilities';

class SongsBySession extends React.Component {

  state = {
    isSessionsLoaded: false,
    sessions: [],
    sessionsError: null,
    currentPage: 0,
    maxPage: 1
  }
  componentDidMount() {
    this.fetchSessions();
  }


  render() {
    const { isSessionsLoaded, sessions, sessionsError } = this.state;
    console.log(sessions);
    return (
      <Container>
        {sessionsError ? <p>Failed to load sessions: {sessionsError.message}</p> :
          [!isSessionsLoaded ? <Row key={1}><Col><p>Loading...</p></Col></Row>
            : <><ul key={2}>{sessions && sessions.map(session => {
              return (
                  
           //     <li key={session.id}>
              //    <Link to={"/recording/" + session.id}>{session.name}</Link>
            //    </li>
            <GetSessionSongs key={session.id} session={session}/>
              );
            })}</ul>
              <Row /></>
          ]}
      </Container>

    )
  }

  fetchSessions() {
      console.log(constants.BASE_URL + `/session/year`);
    fetch(constants.BASE_URL + `/session/year`)
      .then(response => response.json())
      .then(data =>
        this.setState({
          sessions: data.data,
          isSessionsLoaded: true,
        })
      )
      .catch(sessionsError => this.setState({ sessionsError, isSessionsLoaded: true, sessions: [] }));
  }

}

class GetSessionSongs extends React.Component {

    state = {
      isRecordingsLoaded: false,
      recordings: [],
      recordingsError: null,
    }
    
    componentDidMount() {
      this.fetchRecordings(this.props.session.id);
    }
  
  
    render() {
      const { isRecordingsLoaded, recordings, recordingsError } = this.state;
      const {session} = this.props;
      console.log(recordings);

      return (
        <Container key={session.id}>
          {recordingsError ? <p>Failed to load recordings: {recordingsError.message}</p> :
            [!isRecordingsLoaded ? <Row key={1}><Col><p>Loading...</p></Col></Row>
              : <><h2>{session.name}</h2>{formatDateRangeSession(session)}<p></p><ul key={2}>{recordings && recordings.map(recording => {
                return (
                  <li key={recording.id}>
                    <Link to={"/recording/" + recording.id}>{recording.song.title}</Link> ({getArtistName(recording.artist)})
                  </li>
                );
              })}</ul>
                <Row /></>
            ]}
        </Container>
  
      )
    }
  
    fetchRecordings(sessionid) {
      fetch(constants.BASE_URL + `/session/`+sessionid+`/recordings`)
        .then(response => response.json())
        .then(data =>
          this.setState({
            recordings: data.data,
            isRecordingsLoaded: true,
          })
        )
        .catch(recordingsError => this.setState({ recordingsError, isRecordingsLoaded: true, recordings: [] }));
    }
  
  }

export default withRouter(SongsBySession);