import React from 'react';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import { withRouter, Link } from "react-router-dom";
import * as constants from './constants.js';
import PropTypes from 'prop-types';
import { getArtistName } from './utilities';
import Pagination from 'react-bootstrap/Pagination';
import HeaderRow from './headerrow.js';

class ListRecordings extends React.Component {

  state = {
    isRecordingsLoaded: false,
    recordings: [],
    recordingsError: null,
    currentPage: 0,
    maxPage: 1
  }

  constructor(props) {
    super(props);

    var realPageNumber;


    const { pageNumber } = props.match.params;
    if (pageNumber === undefined) {
      realPageNumber = 1;
    } else {
      realPageNumber = pageNumber;
    }

    this.state.currentPage = realPageNumber;


  }

  componentDidMount() {
    this.fetchRecordings(this.state.currentPage);
  }


  render() {
    const { isRecordingsLoaded, recordings, recordingsError, currentPage, maxPages } = this.state;
    return (
      <Container key={currentPage}><Row><HeaderRow/><Card style={{ width: '100%' }}>
        {recordingsError ? <p>Failed to load recordings: {recordingsError.message}</p> :
          [!isRecordingsLoaded ? <Row key={1}><Col><p>Loading...</p></Col></Row>
            : <><ul key={2}>{recordings.content.map(recording => {
              return (
                <li key={recording.id}>
                  <Link to={"/recording/" + recording.id}>{recording.song.title}</Link> ({getArtistName(recording.artist)})
                  {/*<a href={"/recording/" + recording.id}>{recording.song.title}</a>*/}
                </li>
              );
            })}</ul>
              
                <PaginationBox history={this.props.history} currentPage={currentPage} maxPages={maxPages} />
            </>
          ]}
      </Card></Row></Container>

    )
  }

  fetchRecordings(pageNumber) {
    fetch(constants.BASE_URL + `/recording?page=` + (Number(pageNumber) - 1) + `&sort=title`)
      .then(response => response.json())
      .then(data =>
        this.setState({
          recordings: data.data,
          isRecordingsLoaded: true,
          maxPages: data.data.totalPages
        })
      )
      .catch(recordingsError => this.setState({ recordingsError, isRecordingsLoaded: true, recordings: [] }));

      console.log(this.state.maxPages)
  }

}

class PaginationBox extends React.Component {
  state = {
    active: 0,
    items: [],
  }

  constructor(props) {
    super(props);
    const { currentPage, maxPages } = props;
    this.state.active = Number(currentPage);

    var actualMaxPages;
    if (maxPages === undefined) {
      actualMaxPages = 1;
    }
    else {
      actualMaxPages = maxPages;
    }

    for (let number = 1; number <= actualMaxPages; number++) {
      this.state.items.push(
        <Pagination.Item key={number} active={number === this.state.active} onClick={() => this.clickPagination(number)}>
          {number}
        </Pagination.Item>,
      );

    }
  }

  clickPagination(number) {
    this.props.history.push('/list/' + number)
    window.location.reload();

  }


  render() {
    return (
      <Pagination size="sm">{this.state.items}</Pagination>
    )
  }


}

PaginationBox.propTypes = {
  currentPage: PropTypes.string,
  maxPages: PropTypes.string
}

ListRecordings.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      pageNumber: PropTypes.string
    })
  })
}

export default withRouter(ListRecordings);