import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';
import Tab from 'react-bootstrap/Tab';
import { withRouter } from "react-router-dom";
import * as constants from './constants.js';
import PropTypes from 'prop-types';
import { getArtistName, createDate, capitaliseFirstLetter } from './utilities';
import { FormatCredit, FormatRecordingDate } from './reactutilities.js';
import HeaderRow from './headerrow.js';
import './index.css';

class RecordingDisplay extends React.Component {

  state = {
    isRecordingLoaded: false,
    isCreditsLoaded: false,
    recording: {},
    credits: [],
    creditsError: null,
    recordingsError: null,
  }

  componentDidMount() {
    const { recordingID } = this.props.match.params;
    this.fetchRecording(recordingID);
    this.fetchCredits(recordingID);
  }


  render() {
    const { isRecordingLoaded, isCreditsLoaded, recording, credits, creditsError, recordingsError } = this.state;
    const { recordingID } = this.props.match.params;
    if (isCreditsLoaded) {
      credits && credits.sort(function (a, b) {
        var keyA;
        var keyB;

        if (a.role.roletype.sortpriorty === 0) {
          keyA = 100 + a.role.roletype.id;
        } else {
          keyA = a.role.roletype.sortpriority;
        }

        if (b.role.roletype.sortpriorty === 0) {
          keyB = 100 + b.role.roletype.id;
        } else {
          keyB = b.role.roletype.sortpriority;
        }

        if (keyA === keyB) {
          //Same type, sort by description
          var keyC = a.role.description;
          var keyD = b.role.description;
          if (keyC < keyD) return -1;
          if (keyC > keyD) return 1;

        }
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
    }
    return (
      <Container><Row><HeaderRow />
        {recordingsError ? <p>Failed to load recording: {recordingsError.message}</p> : [recording ? <>
          <RecordingDetails recording={recording} credits={credits} isRecordingLoaded={isRecordingLoaded} isCreditsLoaded={isCreditsLoaded} />
          {/* <PersonnelBox credits={credits} isLoaded={isCreditsLoaded} error={creditsError} /> */}
          {recording.notes ? <NotesBox notes={recording.notes} isLoaded={isRecordingLoaded} error={recordingsError} /> : ""}
          <VersionsBox recordingID={recordingID} />
          {/* <QuotesBox recordingID={recordingID} /> */}
        </>
          : <p>Failed to load recording</p>]}
      </Row></Container>

    )
  }


  fetchRecording(iRecordingID) {
    // eslint-disable-next-line no-undef
    fetch(constants.BASE_URL + `/recording/` + iRecordingID)
      .then(response => response.json())
      .then(data =>
        this.setState({
          recording: data.data,
          isRecordingLoaded: true,
        })
      )
      .catch(recordingsError => this.setState({ recordingsError, isRecordingLoaded: true, recording: {} }));
  }

  fetchCredits(iRecordingID) {
    // eslint-disable-next-line no-undef
    fetch(constants.BASE_URL + `/recording/` + iRecordingID + `/credits/`)
      .then(response => response.json())
      .then(data =>
        this.setState({
          credits: data.data,
          isCreditsLoaded: true,
        })
      )
      .catch(creditsError => this.setState({ creditsError, isCreditsLoaded: true, credits: [] }));
  }

}
RecordingDisplay.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      recordingID: PropTypes.string.isRequired
    })
  })
}

class WriterRow extends React.Component {

  state = {
    isLoaded: false,
    songCredits: [],
    error: null
  }

  componentDidMount() {
    this.fetchWriters();
  }

  render() {
    const { error, isLoaded, songCredits } = this.state;
    const { credits } = this.props;

    return (
      <>
        <Row>
          <Col className="justify-content-center text-center" style={{ fontWeight: "bold" }}>Writing</Col>
        </Row>
        {error ?
          <Row key={43}><Col><p>Failed to load writers: {error.message}</p></Col></Row>
          : [!isLoaded ? <Row key={44}><Col><p>Loading...</p></Col></Row>
            : songCredits && songCredits.map(writer => {
              return (
                <FormatCredit key={writer.id} id={writer.id} role={writer.role.description} performer={getArtistName(writer.performer)} notes={writer.notes} />
              );
            })
          ]}
        {credits && credits.map(person => {
          var sReturn = ""

          if (person.role.roletype.writer) {
            sReturn = <FormatCredit key={person.id} id={person.id} role={person.role.description} performer={getArtistName(person.performer)} notes={person.notes} />;
          }

          return sReturn;
        })}
      </>
    )
  }

  fetchWriters() {
    // eslint-disable-next-line no-undef
    fetch(constants.BASE_URL + `/songcredit/writers/` + this.props.song)
      .then(response => response.json())
      .then(data =>
        this.setState({
          songCredits: data.data,
          isLoaded: true,
        })
      )
      .catch(error => this.setState({ error, isLoaded: true }));
  }

}

WriterRow.propTypes = {
  credits: PropTypes.array.isRequired,
  song: PropTypes.number.isRequired
}

class ProducerRow extends React.Component {

  render() {
    const { isLoaded, credits } = this.props;
    var rProducerEntries = [];

    if (isLoaded) {
      //See if there are any producers
      credits && credits.map(rCredit => {
        if (rCredit.role.roletype.producer) {
          rProducerEntries.push(rCredit);
        }
      })
    }

    return (
      <>
        {rProducerEntries.length > 0 ? <Row>
          <Col className="justify-content-center text-center" style={{ fontWeight: "bold" }}>Production</Col>
        </Row>
          : ""}

        {!isLoaded ? <Row><Col><p>Loading...</p></Col></Row>
          :
          rProducerEntries && rProducerEntries.map(producer => {
            var sReturn = ""

            if (producer.role.roletype.producer) {
              sReturn = <FormatCredit key={producer.id} id={producer.id} role={producer.role.description} performer={getArtistName(producer.performer)} notes={producer.notes} />
            }

            return sReturn;
          })
        }
      </>
    )
  }

}

ProducerRow.propTypes = {
  credits: PropTypes.array.isRequired,
  isLoaded: PropTypes.bool.isRequired
}

/* class RecordingDates extends React.Component {

  render() {
    const { recordingDates, isLoaded } = this.props;
    return (
      <>
        <Row>
          <Col className="justify-content-center text-center" style={{ fontWeight: "bold" }}>Session dates</Col>
        </Row>
        {!isLoaded ? <Row><Col><p>Loading...</p></Col></Row>
          : recordingDates.map(date => {
            return (
              <FormatRecordingDate key={date.id} date={date}/>
            );
          })}
      </>
    )
  }

}

RecordingDates.propTypes = {
  recordingDates: PropTypes.array.isRequired,
  isLoaded: PropTypes.bool.isRequired
} */

class FirstReleaseDate extends React.Component {

  state = {
    isLoaded: false,
    releases: [],
    error: null
  }

  componentDidMount() {
    this.fetchFirstRelease();
  }

  render() {
    const { error, isLoaded, releases } = this.state;

    return (
      <>
        {error ?
          //<Row><Col><p>Failed to load release information: {error.message}</p></Col></Row>
          ""
          : [!isLoaded ? <Row key={45}><Col><p>Loading...</p></Col></Row>
            : [!releases || releases.length > 0 ? ""//<Row key={45}><Col><p>No data2</p></Col></Row> 
              : releases.map(release => {
                return (
                  <Row key={release.id}><Col>First release:</Col><Col>{createDate(release.releaseid.year, release.releaseid.month, release.releaseid.day)}</Col></Row>
                );
              })]
          ]}
      </>
    )
  }

  fetchFirstRelease() {
    const { versions } = this.props;

    var masterVersion = 0;

    for (var i = 0; i < versions.length; i++) {
      if (versions[i].master === 1) {
        masterVersion = versions[i].id;
        break;
      }
    }
    if (masterVersion === 0) {
      this.setState({ error: { error: "No master version defined" }, isLoaded: true });
    } else {

      // eslint-disable-next-line no-undef
      fetch(constants.BASE_URL + `/appearsOn/firstrelease/` + masterVersion)
        .then(response => response.json())
        .then(data =>
          this.setState({
            releases: data.data,
            isLoaded: true,
          })
        )
        .catch(error => this.setState({ error, isLoaded: true, releases: [] }));
    }
  }

}

FirstReleaseDate.propTypes = {
  versions: PropTypes.array.isRequired,
}

/* class PersonnelBox extends React.Component {

  render() {
    const { error, isLoaded, credits } = this.props;
    var sLastRole = "";
    var iCount = 0;

    return (
      <Card style={{ width: '100%' }}>
        <Card.Body>
          <Card.Title>Personnel</Card.Title>
          <Container key={10222}>
            {error ?
              <Row key={444}><Col><p>Failed to load credits: {error.message}</p></Col></Row>
              : [!isLoaded ? <Row key={1}><Col><p>Loading...</p></Col></Row>
                : credits && credits.map(credit => {
                  var sValue = "";
                  var sValue2 = "";

                  if (credit.role.roletype.writer === 0 & credit.role.roletype.producer === 0) {
                    if (sLastRole !== credit.role.roletype.description){
                      sLastRole = credit.role.roletype.description;
                      sValue2 = <Row key={"roleCount" + ++iCount}><Col style={{ fontWeight: "bold" }}>{capitaliseFirstLetter(sLastRole)}</Col></Row>
                    }

                    sValue = <FormatCredit key={"credit"+credit.id} id={credit.id} role={credit.role.description} performer={getArtistName(credit.performer)} notes={credit.notes} />
                  }

                  return <>{sValue2}{sValue}</>;
                })
              ]}

          </Container>
        </Card.Body>
      </Card>
    )
  }

}

PersonnelBox.propTypes = {
  credits: PropTypes.array.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  error: PropTypes.object
} */

class VersionsBox extends React.Component {

  state = {
    isVersionsLoaded: false,
    versions: [],
    versionsError: null,
  }

  componentDidMount() {
    this.fetchVersions();
  }

  render() {
    const { versions, versionsError, isVersionsLoaded } = this.state;
    var iCountList = 0;
    var iCountTab = 0;

    versions.sort((a, b) => this.sortVersion(a, b));

    return (
      <Card style={{ width: '100%' }}>
        <Card.Body>
          <Card.Title>Versions</Card.Title>

          {versionsError ?
            <Row><Col><p>Failed to load versions: {versionsError.message}</p></Col></Row>
            : [!isVersionsLoaded ? <Row key={1}><Col><p>Loading...</p></Col></Row>
              :
              <Tab.Container key={1} id="list-group-tabs-example" defaultActiveKey="#link1">
                <Row>
                  <Col sm={4}>
                    <ListGroup>

                      {versions && versions.map(version => {
                        return (
                          <ListGroup.Item action href={"#link" + ++iCountList} key={version.id}>{version.description}</ListGroup.Item>
                        );
                      })}
                    </ListGroup>
                  </Col>
                  <Col sm={8}>
                    <Tab.Content>
                      {versions && versions.map(version => {
                        return (
                          <VersionDetails key={version.id} tab={++iCountTab} versionID={version.id} notes={version.notes} duration={version.length} youtube={version.youtube}/>
                        );
                      })}


                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            ]}
        </Card.Body>
      </Card>

    )
  }

  sortVersion(a, b) {
    var iReturn;
    switch (true) {
      case a.sortpriority == null:
        iReturn = 1;
        break;

      case b.sortpriority == null:
        iReturn = -1;
        break;

      case a.sortpriority > b.sortpriority:
        iReturn = 1;
        break;

      case a.sortpriority < b.sortpriority:
        iReturn = -1;
        break;

      case a.description > b.description:
        iReturn = 1;
        break;

      case a.description < b.description:
        iReturn = -1;
        break;

      case a.master:
        iReturn = 1
        break;

      case b.master:
        iReturn = -1
        break;

      default:
        iReturn = 0;
        break;

    }

    return iReturn;
  }

  fetchVersions() {
    // eslint-disable-next-line no-undef
    fetch(constants.BASE_URL + `recording/` + this.props.recordingID + `/versions`)
      .then(response => response.json())
      .then(data =>
        this.setState({
          versions: data.data,
          isVersionsLoaded: true,
        })
      )
      .catch(versionsError => this.setState({
        versionsError,
        isVersionsLoaded: true,
        //Reset this to stop invalid map errors
        versions: []
      }));
  }

}

VersionsBox.propTypes = {
  recordingID: PropTypes.string.isRequired,
}

class VersionDetails extends React.Component {
  state = {
    isAppearancesLoaded: false,
    appearances: [],
    appearancesError: null,
  }

  componentDidMount() {
    this.fetchAppearances();
  }

  render() {
    const { versionID, tab, notes, duration, youtube } = this.props;
    const { appearances, isAppearancesLoaded, appearancesError } = this.state;

    console.log(appearances);
    return (
      <Tab.Pane eventKey={"#link" + tab} id={versionID}>
     
     {appearancesError ? <Row><Col><p>Failed to load version details: {appearancesError.message}</p></Col></Row> : 
     [!isAppearancesLoaded ? <Row key={46}><Col><p>Loading...</p></Col></Row> : 
      <>{Number(duration) === 0 ? "" : "Duration: " + duration} <p>{notes}</p> 
      {!youtube ? "" : <a href={'https://www.youtube.com/watch?v=' + youtube}>Listen on YouTube</a>}
       <ul key={47}>{ appearances !== undefined && appearances.sort((a, b) => this.sortAppearances(a, b)).map(appearance => {
        return (
          <li key={appearance.id}>
            <AppearanceDisplay appearance={appearance} />
          </li>
        );
      })}</ul></>
    ]
     }

     </Tab.Pane>
    )
  }

  fetchAppearances() {
    // eslint-disable-next-line no-undef
    fetch(constants.BASE_URL + `version/` + this.props.versionID + `/appearson`)
      .then(response => response.json())
      .then(data =>
        this.setState({
          appearances: data.data,
          isAppearancesLoaded: true,
        })
      )
      .catch(appearancesError => this.setState({
        appearancesError,
        isAppearancesLoaded: true,
        //Reset this to stop invalid 
        appearances: []
      }));
  }

  sortAppearances(a, b) {
    var iReturn;

    switch (true) {
    
case a.releaseid.year === 0:
  iReturn  =1;
  break;

  case b.releaseid.year === 0:
  iReturn  =-1;
  break;


      case a.releaseid.year > b.releaseid.year:
        iReturn = 1;
        break;

      case a.releaseid.year < b.releaseid.year:
        iReturn = -1;
        break;

      case a.releaseid.month > b.releaseid.month:
        iReturn = -1;
        break;

      case a.releaseid.month < b.releaseid.month:
        iReturn = 1;
        break;

      case a.releaseid.day < b.releaseid.day:
        if (b.releaseid.day === 0) {
          iReturn = 1;
        } else {
          iReturn = -1;
        }
        break;

      case a.releaseid.day > b.releaseid.day:
        iReturn = -1
        break;

      case a.firstappearance:
        iReturn = 1
        break;

      case b.firstappearance:
        iReturn = -1
        break;

      default:
        iReturn = 0;
        break;

    }
    // console.log(iReturn);
    return iReturn;


  }

}

VersionDetails.propTypes = {
  versionID: PropTypes.number.isRequired,
  tab: PropTypes.number.isRequired,
  notes: PropTypes.string,
  duration: PropTypes.string,
  youtube: PropTypes.string
}

class AppearanceDisplay extends React.Component {

  render() {
    const { appearance } = this.props;
    const release = appearance.releaseid;

    return (
      <>
        {release.releasetype.italics ? <i>{release.title}</i> : [release.releasetype.quotes ? '"' + release.title + '"' : release.title]} {release.unreleased ? (<b>unreleased</b>) : ""} {release.releasetype.description} {release.description === "" ? "" : " (" + release.description + ")"} ({createDate(release.year, release.month, release.day)}, {release.country}, {release.label}, {release.catalogNo}) {appearance.firstappearance ? <b>First appearance</b> : ""}
      </>
    )

  }
}

AppearanceDisplay.propTypes = {
  appearance: PropTypes.shape({
    releaseid: PropTypes.object,
    firstAppearance: PropTypes.bool
  })
}

class NotesBox extends React.Component {

  render() {

    const { notes, error, isLoaded } = this.props;

    return (

      <Card style={{ width: '100%' }}>
        <Card.Body>
          <Card.Title>Notes</Card.Title>
          <Card.Text>
            {error ?
              "Failed to load notes: " && error.message
              : [!isLoaded ? "Loading..."
                : notes]}
          </Card.Text>

        </Card.Body>
      </Card>
    )
  }
}

NotesBox.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string
  }),
  notes: PropTypes.string,
  isLoaded: PropTypes.bool.isRequired

}

/* class QuotesBox extends React.Component {
  state = {
    isQuotesLoaded: false,
    quotes: [],
    quotesError: null,
  }

  componentDidMount() {
    this.fetchQuotes();
  }
  render() {
      const {quotesError, quotes, isQuotesLoaded} = this.state;
    return(

      <Card style={{ width: '100%'}}>
        <Card.Body>
        <Card.Title>Quotes</Card.Title>
        {quotesError ?
          <Card.Text key={47}>Failed to load version details: {quotesError.message}</Card.Text>
          : [!isQuotesLoaded ? <Card.Text key={48}>Loading...</Card.Text>
            : <ListGroup key={878678} variant="flush">{quotes && quotes.map(quote => {
              return (
                <ListGroup.Item key={"quote" + quote.id}>&quot;{quote.quote}&quot; - {quote.quoteby}, {createDate(quote.year, quote.month, quote.day)}, {quote.source}</ListGroup.Item>
              );
            })}</ListGroup>]}
        </Card.Body>
      </Card>

    )
  }

  fetchQuotes() {
    // eslint-disable-next-line no-undef
    fetch(constants.BASE_URL + `recording/` + this.props.recordingID + `/quotes`)
      .then(response => response.json())
      .then(data =>
        this.setState({
          quotes: data.data,
          isQuotesLoaded: true,
        })
      )
      .catch(quotesError => this.setState({
        quotesError, 
        isQuotesLoaded: true, 
        quotes: []
      }));
  }

}

QuotesBox.propTypes = {
  recordingID: PropTypes.string.isRequired

} */

class RecordingDetails extends React.Component {

  render() {
    const { recording, credits, isRecordingLoaded, isCreditsLoaded } = this.props;

    if (isRecordingLoaded){
    var sTitle = recording.song.title;
   
    }
    return (
      <React.Fragment key={recording.id}>
        {isRecordingLoaded ? (
          <>
            <Card style={{ width: '75%' }}>
              <Card.Body>
                <Card.Title>{sTitle}</Card.Title>
                <Container>
                  <Row>
                    <Col>Artist:</Col>
                    <Col>{getArtistName(recording.artist)}</Col>
                  </Row>
                  <FirstReleaseDate versions={recording.versionsList} />
                  {/* <RecordingDates recordingDates={recording.recordingDatesList} isLoaded={isRecordingLoaded} /> */}
                  <WriterRow song={recording.song.id} credits={credits} />

                  <ProducerRow credits={credits} isLoaded={isCreditsLoaded} />

                </Container>
              </Card.Body>
            </Card>
            <Card style={{ width: '25%' }}><div class="d-flex align-items-center justify-content-center"><Image src={constants.IMAGE_REF + recording.image.filename} height="90%" width="90%" alt={recording.image.hovertext} /></div></Card>
          </>
        ) : (
            <h3>Loading...</h3>
          )}
      </React.Fragment>
    );

  }


}

RecordingDetails.propTypes = {
  recording: PropTypes.object,
  credits: PropTypes.array,
  isRecordingLoaded: PropTypes.bool,
  isCreditsLoaded: PropTypes.bool
}

export default withRouter(RecordingDisplay);